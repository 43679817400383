import React, { Component } from 'react';
import './App.scss';

class Back extends Component {
  render() {
    return (
      <div>This is the back</div>
    );
  }
}

export default Back;
