import React, { Component } from 'react';
import './App.scss';

import BigHeading from './components/BigHeading/BigHeading';
import BulletList from './components/BulletList/BulletList';
import DetailedBulletList from './components/BulletList/DetailedBulletList';
import DotGroup from './components/DotGroup/DotGroup';
import Footer from './components/Footer/Footer';
import KeyCertification from './components/KeyCertification/KeyCertification';
import NameHeader from './components/NameHeader/NameHeader';
import NumberedHeading from './components/NumberedHeading/NumberedHeading';
import SmallHeading from './components/SmallHeading/SmallHeading';

// Import Configs
import educationHeadings from './config/educationConfig.json';
import workHeadings from './config/workConfig.json';
import skillsDots from './config/skillsConfig.json';
import toolBullets from './config/toolConfig.json';
import langBullets from './config/langConfig.json';
import personalConfig from './config/personalConfig.json';
import hobbiesBullets from './config/hobbiesConfig.json';

class Front extends Component {
  render() {
    const title = personalConfig[0].title;
    return (
      <div>
        <div className="content">
          <section className="main">
            {/*<a className='flip-side' href="/back">See Back</a> */}

            <BigHeading iconClass='fa fa-briefcase'
              title='Work Experience'/>
              <NumberedHeading headings={workHeadings}/>

            <BigHeading iconClass='fa fa-graduation-cap'
              title='Education'/>
              <NumberedHeading headings={educationHeadings}/>

            <BigHeading iconClass='fa fa-tasks'
              title='Skills'
              tightHeading/>
              <DotGroup dotGroup={skillsDots}/>

          </section>
          <aside className="sidebar">
            <NameHeader firstName='Jeris'
              lastName='Rue'
              title='Cyber Security Leader'/>

            <div className='sidebar-content'>
              <SmallHeading iconClass='fa fa-file-text-o'
                title='Objective'/>
                <div className='objective-text'>
                  {`${title} seeking Security Leadership position in a fast-paced and challenging environment, for a world-class company that values the importance of security.`}
                </div>

              {/*<SmallHeading iconClass='fa fa-university'*/}
              <SmallHeading iconClass='fa fa-check-square-o'
                title='Certifications'/>
                <KeyCertification title='Offensive Security Certified Professional (OSCP)'
                  date='Issued Nov 2016'
                  subtext='OS-101-06257' />
                <KeyCertification title='GIAC Reverse Engineering Malware (GREM)'
                  date='Expires Apr 2023'
                  subtext='#4267' />
                <KeyCertification title='GIAC Certified Incident Handler (GCIH)'
                  date='Expires Nov 2025'
                  subtext='#23048' />
                <KeyCertification title='CompTIA A+'
                  date='Issued Oct 2010'
                  subtext='23048W2FSPN6B23VQCE6E' />

              <SmallHeading iconClass='fa fa-keyboard-o'
                title='Tools/Technology'/>
                <BulletList bulletList={toolBullets}/>

              <SmallHeading iconClass='fa fa-code'
                title='Languages'/>
                <BulletList bulletList={langBullets}/>

              <SmallHeading iconClass='fa fa-user-o'
                title='Interests & Hobbies'/>
                <DetailedBulletList bulletList={hobbiesBullets}
                  paddedList />

            </div>
          </aside>
          {/*
          <a href="/back"
            target="_blank"
            className='curl-container'>
            <div className="curl">
              <div className="curl-text">
                Back
              </div>
            </div>
          </a>
          */}
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Front;
