import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BulletList.scss';

class BulletList extends Component {
  static displayName = 'BulletList';
  static propTypes = {
    bulletList: PropTypes.array
  };
  render() {
    let leftColNum = 0;
    const bullets = this.props.bulletList;
    const colsClass = bullets.length > 5 ? 'two-cols' : 'one-col';
    
    if (bullets.length > 5) {
      leftColNum = Math.ceil(bullets.length / 2);
    }

    const bulletList = bullets.map((ele, idx) => {
      const liClass = idx < leftColNum ? 'li-border' : 'li-padding-left';
      const liPadding = idx < (bullets.length / 2) && idx + 1 > (bullets.length / 2) ? '' : 'li-padding';
      return (
        <li className={`${liClass} ${liPadding}`}
          key={`${ele}-${idx}`}>
          <span className='bullet-icon-circle'/>
          {ele}
        </li>
      );
    });

    return (
      <ul className={`bullet-list ${colsClass}`}>
        {bulletList}
      </ul>
    );
  }
}

export default BulletList;
