import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import App from './App';
import Front from './Front';
import Back from './Back';
import * as serviceWorker from './serviceWorker';

if (window.location.pathname === '/back') {
    ReactDOM.render(<Back />, document.getElementById('root'));
} else {
    ReactDOM.render(<Front />, document.getElementById('root'));
}


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
