import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FooterItem from './FooterItem';

import './Footer.scss';

class Footer extends Component {
  static displayName = 'Footer';
  static propTypes = {
    iconClass: PropTypes.string,
    title: PropTypes.string
  };
  render() {
    return (
      <footer>
        <div className='footer-line'>
          <FooterItem title={["jeris.rue@gmail.com"]}
            iconClass="fa fa-envelope"/>
          <FooterItem title={["+1.402.578.4786"]}
            iconClass="fa fa-phone"/>
          <FooterItem title={["twitter.com/elaboraterues"]}
            iconClass="fa fa-twitter"
            links/>
        </div>
        <div className='footer-line'>
          <FooterItem title={["jerisrue.com"]}
            /*iconClass="fa fa-code-fork"*/
            iconClass="fa fa-chrome"
            links/>
          <FooterItem title={["linkedin.com/in/jerisrue"]}
            iconClass="fa fa-linkedin"
            links/>
          <FooterItem title={["github.com/jrue3084"]}
            iconClass="fa fa-github"
            links/>
        </div>
        <div className='footer-line'>
          <div className='footer-text'>
            This resume was written in javascript using react.js, stored in github, and deployed via docker, cloudflare, and nginx.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
