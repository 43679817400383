import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './NameHeader.scss';
import variables from '../../static/styles/variables.scss';

const sidebarWidth = {
  width: variables.sidebarWidth
}

class NameHeader extends Component {
  static displayName = 'NameHeader';
  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string
  };
  render() {
    return (
      <div className='named-header'>
        <div className='name'>
          {this.props.firstName} <b>{this.props.lastName}</b>
        </div>
        <div className='title'>
          {this.props.title}
        </div>
        <svg height="112"
          style={sidebarWidth}>
        {/* <svg className='svg-group'> */}
          {/* <circle className='svg-circle'/> */}
          <rect className='svg-rect'/>
          {/* <circle cx="56"
            cy="56"
            r="56"
            stroke="white"
            strokeWidth="2"
            fill="transparent"/> */}
        </svg>
      </div>
    );
  }
}

export default NameHeader;
