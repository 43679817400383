import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DotBar from './DotBar';
import './DotGroup.scss';

class DotGroup extends Component {
  static displayName = 'DotGroup';
  static propTypes = {
    dotGroup: PropTypes.array
  };

  render() {
    const dotBars = this.props.dotGroup.map((nested, idx) => {
      const dotRow = nested.map((ele, indx) => {
        const middleColClass = indx % 2 ? ' middle-col' : ''; 
        const lastColClass = indx === 2 ? ' last-col' : '';

        return (
          <div key={ele.title}
            className={`dot-bar${middleColClass}${lastColClass}`}>
            <div className='dot-title'>
              {ele.title}
            </div>
            <div className='dots'>
              <DotBar hideWording={!!(idx || indx)}
                numDots={ele.dots} />
            </div>
          </div>
        );
      });

      return (
        <div key={idx}
          className='dot-row'>
          {dotRow}
        </div>
      );
    });

    return (
      <div className="dot-bar-grouping">
        {dotBars}
        <div className="dot-bar-legend">
          <div className="dot-bar-legend-header">Legend: </div>
          <div className="dot-bar-legend-item">Beginner</div>
          <div className="dot-bar-legend-item">Familiar</div>
          <div className="dot-bar-legend-item">Proficient</div>
          <div className="dot-bar-legend-item">Expert</div>
          <div className="dot-bar-legend-item">Master</div>
        </div>
      </div>
    );
  }
}

export default DotGroup;
