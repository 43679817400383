import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DotGroup.scss';

class DotBar extends Component {
  static displayName = 'DotGroup';
  static propTypes = {
    hideWording: PropTypes.bool,
    numDots: PropTypes.number
  };

  render() {
    const numYears = 5;
    let dots = [];

    for (let i = 0; i < numYears; i++) {
      if (i < this.props.numDots) {
        dots.push(
          <div key={i}
            className='dot filled'/>
        );
      } else {
        dots.push(
          <div key={i}
            className='dot'/>
        );
      }
    }

    // if (!this.props.hideWording) {
    //   dots.push(
    //     <div key={'legend'}
    //       className='legend'>
    //       (Yrs Exp)
    //     </div>
    //   );
    // }

    return (
      <div>
        {dots}
      </div>
    );
  }
}

export default DotBar;
